import { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useCompany } from 'hooks/useCompany';
import { useLocalStorage } from 'hooks/useLocalStorage';
import LoadingIndicator from '@/hammr-ui/components/LoadingIndicator';

const HomePage = () => {
  const firstUpdate = useRef(true);
  const router = useRouter();
  const { company } = useCompany();

  const [calledPush, setCalledPush] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [user] = useLocalStorage('user', null);

  // redirect on page mount
  useEffect(() => {
    if (user && company?.isPayrollEnabled && !calledPush) {
      setCalledPush(true);
      router.push('/dashboard');
      return;
    } else if (user && user?.isCompanyAdmin && !company?.isPayrollEnabled && !calledPush) {
      setCalledPush(true);
      router.push('/dashboard');
      return;
    } else if (user && !company?.isPayrollEnabled && !calledPush) {
      setCalledPush(true);
      router.push('/timesheets');
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, company?.isPayrollEnabled, calledPush]);

  useEffect(() => {
    // means they've logged in before - in logged out state
    // wait for user to trigger the first update in below useEffect hook before looking at user object
    if (user === null && firstUpdate.current === false) {
      if (!calledPush) {
        setCalledPush(true);
        router.push('/login');
        return;
      }
    }

    if (user === false) {
      if (!calledPush) {
        setCalledPush(true);
        router.push('/login');
        return;
      }
    }
  }, [calledPush, router, user]);

  useEffect(() => {
    // initial user object null - updating firstUpdate when user changes after useLocalStorage finishes
    if (user === null) {
      // updating firstUpdate
      firstUpdate.current = false;
    }
  }, [user]);

  return (
    <div className="flex h-screen items-center justify-center">
      <LoadingIndicator size="md" />
    </div>
  );
};

export default HomePage;

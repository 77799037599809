interface Props extends React.SVGProps<SVGSVGElement> {}

export default function LoadingCircle(props: Props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fill="#EE6023"
        d="M16 4h-.03a2 2 0 1 1-.009-4h.078a2 2 0 1 1-.01 4H16Zm-3.477-1.708a2 2 0 0 1-1.088 2.61l-.027.011-.027.012a2 2 0 1 1-1.54-3.692l.036-.015.036-.015a2 2 0 0 1 2.61 1.089Zm6.954 0a2 2 0 0 1 2.61-1.089l.036.015.036.015a2 2 0 0 1-1.54 3.692l-.027-.012-.027-.01a2 2 0 0 1-1.088-2.611ZM7.542 4.666a2 2 0 0 1-.007 2.828l-.02.02-.02.021a2 2 0 1 1-2.836-2.821l.027-.028.028-.027a2 2 0 0 1 2.828.007Zm16.916 0a2 2 0 0 1 2.828-.007l.028.027.027.028a2 2 0 1 1-2.835 2.821l-.02-.02-.021-.02a2 2 0 0 1-.007-2.83Zm-20.61 4.1a2 2 0 0 1 1.077 2.615l-.012.027-.01.027a2 2 0 0 1-3.7-1.522l.015-.036.015-.036a2 2 0 0 1 2.616-1.076Zm24.303 0a2 2 0 0 1 2.616 1.075l.015.036.015.036a2 2 0 0 1-3.7 1.522l-.01-.027-.012-.027a2 2 0 0 1 1.076-2.616Zm-26.146 5.2A2 2 0 0 1 4 15.971v.059a2 2 0 1 1-4 .009v-.078a2 2 0 0 1 2.005-1.995Zm27.99 0A2 2 0 0 1 32 15.961v.078a2 2 0 0 1-4-.01v-.059a2 2 0 0 1 1.995-2.004Zm-27.703 5.51a2 2 0 0 1 2.61 1.09l.011.026.012.027a2 2 0 0 1-3.692 1.54l-.015-.036-.015-.036a2 2 0 0 1 1.089-2.61Zm27.416 0a2 2 0 0 1 1.089 2.611l-.015.036-.015.036a2 2 0 0 1-3.692-1.54l.012-.027.01-.027a2 2 0 0 1 2.611-1.088ZM4.666 24.459a2 2 0 0 1 2.828.007l.02.02.021.02a2 2 0 1 1-2.821 2.836l-.028-.027-.027-.028a2 2 0 0 1 .007-2.828Zm22.668 0a2 2 0 0 1 .007 2.828l-.027.028-.028.027a2 2 0 1 1-2.821-2.835l.02-.02.02-.021a2 2 0 0 1 2.83-.007ZM8.765 28.15a2 2 0 0 1 2.616-1.076l.027.012.027.01a2 2 0 1 1-1.522 3.7l-.036-.015-.036-.015a2 2 0 0 1-1.076-2.616Zm14.47 0a2 2 0 0 1-1.076 2.616l-.036.015-.036.015a2 2 0 0 1-1.522-3.7l.027-.01.027-.012a2 2 0 0 1 2.616 1.076Zm-9.269 1.844A2 2 0 0 1 15.971 28h.059a2 2 0 1 1 .009 4h-.078a2 2 0 0 1-1.995-2.005Z"
      />
    </svg>
  );
}

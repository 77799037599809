import LoadingCircle from '@/hammr-icons/LoadingCircle';
import { cn } from '../lib/utils';

interface Props {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  showText?: boolean;
  text?: string;
}

function LoadingIndicator({ size = 'sm', showText = true, text }: Props) {
  return (
    <div
      className={cn('flex w-fit flex-col items-center', {
        'gap-4': size !== 'xl',
        'gap-5': size === 'xl',
      })}
    >
      <LoadingCircle
        className={cn('animate-spin', {
          'h-5 w-5': size === 'xs',
          'h-8 w-8': size === 'sm',
          'h-12 w-12': size === 'md',
          'h-14 w-14': size === 'lg',
          'h-16 w-16': size === 'xl',
        })}
        style={{
          maskImage:
            'conic-gradient(from 259deg at 50% 50%, #000 0deg, rgba(0, 0, 0, 0.00) 331.875deg, rgba(0, 0, 0, 0.00) 360deg)',
          animationDirection: 'reverse',
        }}
      />
      {showText && (
        <span
          className={cn('text-sm font-medium text-sub-600', {
            'text-sm': size === 'sm' || size === 'md',
            'text-lg': size === 'lg',
            'text-xl': size === 'xl',
          })}
        >
          {text || 'Loading...'}
        </span>
      )}
    </div>
  );
}

export default LoadingIndicator;
